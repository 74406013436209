import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Login from "./Login";
import api from "./utils/api";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { formatForDateInput, safeDateToISO } from './utils/dateUtils';

// Register required AG Grid modules
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const App = () => {
  const [rowData, setRowData] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [userRole, setUserRole] = useState("");
  const [userId, setUserId] = useState(null);
  const [showNewOpportunityModal, setShowNewOpportunityModal] = useState(false);

  const [auditLogs, setAuditLogs] = useState([]);
  const [isAuditLogModalOpen, setIsAuditLogModalOpen] = useState(false);
  const [selectedOpportunityId, setSelectedOpportunityId] = useState(null);

  // New opportunity form state
  const [newOpportunity, setNewOpportunity] = useState({
    client: '',
    opportunity: '',
    stage: '',
    solicitation_link: '',
    solicitation_release: null,
    response_due: '',
    response_link: '',
    asg_role: '',
    lead: '',
    division_vp: '',
    scope: '',
    vehicle: '',
    set_aside: '',
    tcv: '',
    incumbents: '',
    partners: '',
    status: 'New'
  });

  const STAGE_OPTIONS = [
    "1-IDENTIFY",
    "2-QUALIFY",
    "3-CAPTURE",
    "4-PROPOSE",
    "5-SOURCE SELECTION",
    "6-PROPOSAL",
    "7-SUBMITTED",
    "8-CLOSED WIN",
    "9-CLOSED NOT WIN"
  ];

  const ASG_ROLE_OPTIONS = [
    "Prime",
    "Sub",
    "JV"
  ];

  // AG Grid modules configuration
  const modules = [ClientSideRowModelModule];

  const isEditableByUser = (params) => userRole === "Leads" || params.data.creator === userId;

  // Formatting for display
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  const columnDefs = [
    { headerName: "ID", field: "id", editable: false },
    { headerName: "Client", field: "client", editable: isEditableByUser },
    {
      headerName: "Opportunity",
      field: "opportunity",
      editable: isEditableByUser,
      cellEditor: 'agLargeTextCellEditor', // Use AG Grid's built-in large text editor
      cellEditorPopup: true, // Enable floating popup
      cellEditorParams: {
        maxLength: 500, // Optional: Limit the text length
        rows: 5, // Optional: Number of rows in the text area
        cols: 50 // Optional: Number of columns in the text area
      }
    },
    {
      headerName: "Stage",
      field: "stage",
      editable: isEditableByUser,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: STAGE_OPTIONS },
      sort: 'asc', //Default ascending sort
      sortingOrder: ['asc', 'desc'], // Allowed sort directions
      comparator: (vauleA, valueB) => {
        const stageOrder = STAGE_OPTIONS;
        return stageOrder.indexOf(vauleA) - stageOrder.indexOf(valueB);
      }
    },
    {
      headerName: "Solicitation Link",
      field: "solicitation_link",
      editable: isEditableByUser,
      cellEditor: 'agLargeTextCellEditor', // Use AG Grid's built-in large text editor
      cellEditorPopup: true, // Enable floating popup
      cellEditorParams: {
        maxLength: 500, // Optional: Limit the text length
        rows: 5, // Optional: Number of rows in the text area
        cols: 50 // Optional: Number of columns in the text area
      }
    },
    {
      headerName: "Solicitation Release", field: "solicitation_release", editable: isEditableByUser, valueFormatter: (params) => formatDate(params.value)
    },
    { headerName: "Response Due", field: "response_due", editable: isEditableByUser },
    {
      headerName: "Response Link",
      field: "response_link",
      editable: isEditableByUser,
      cellEditor: 'agLargeTextCellEditor', // Use AG Grid's built-in large text editor
      cellEditorPopup: true, // Enable floating popup
      cellEditorParams: {
        maxLength: 500, // Optional: Limit the text length
        rows: 5, // Optional: Number of rows in the text area
        cols: 50 // Optional: Number of columns in the text area
      }
    },
    {
      headerName: "ASG Role",
      field: "asg_role",
      editable: isEditableByUser,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: ASG_ROLE_OPTIONS }
    },
    { headerName: "Lead", field: "lead", editable: isEditableByUser },
    { headerName: "Division VP", field: "division_vp", editable: isEditableByUser },
    {
      headerName: "Scope",
      field: "scope",
      editable: isEditableByUser,
      cellEditor: 'agLargeTextCellEditor', // Use AG Grid's built-in large text editor
      cellEditorPopup: true, // Enable floating popup
      cellEditorParams: {
        maxLength: 500, // Optional: Limit the text length
        rows: 5, // Optional: Number of rows in the text area
        cols: 50 // Optional: Number of columns in the text area
      }
    },
    { headerName: "Vehicle", field: "vehicle", editable: isEditableByUser },
    { headerName: "Set-aside", field: "set_aside", editable: isEditableByUser },
    {
      headerName: "TCV",
      field: "tcv",
      editable: isEditableByUser,
      valueFormatter: (params) => {
        // Format as USD with commas and 2 decimal places
        return params.value !== null && params.value !== undefined
          ? '$' + Number(params.value).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
          : '';
      },
      valueParser: (params) => {
        // Remove any non-numeric characters when editing
        return params.newValue ? parseFloat(params.newValue.replace(/[^0-9.]/g, '')) : null;
      }
    },
    {
      headerName: "Incumbents", field: "incumbents", editable: isEditableByUser,
      tooltipField: 'incumbents',  // Show full text on hover
      cellStyle: { whiteSpace: 'normal' },
      autoHeight: true
    },
    {
      headerName: "Partners",
      field: "partners",
      editable: isEditableByUser,
      cellEditor: 'agLargeTextCellEditor', // Use AG Grid's built-in large text editor
      tooltipField: 'partners',  // Show full text on hover
      cellStyle: { whiteSpace: 'normal' },
      autoHeight: true,
      cellEditorPopup: true, // Enable floating popup
      cellEditorParams: {
        maxLength: 500, // Optional: Limit the text length
        rows: 5, // Optional: Number of rows in the text area
        cols: 50 // Optional: Number of columns in the text area
      }
    },
    { headerName: "Status", field: "status", editable: isEditableByUser },
    { headerName: "Creation Time", field: "creation_time", editable: false },
    {
      headerName: "Update Time",
      field: "update_time",
      editable: false,
      sort: 'asc', //Default ascending sort
      sortingOrder: ['asc', 'desc'], // Allowed sort directions
      valueFormatter: (params) => {
        return params.value ? new Date(params.value).toLocaleString() : '';
      }
    },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: (params) => (
        <button
          onClick={() => handleViewAuditLog(params.data.id)}
          className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
        >
          View Change Log
        </button>
      ),
      sortable: false,
      filter: false,
      editable: false,
    },
  ];

  const fetchRecords = useCallback(async () => {
    try {
      const response = await api.get("/opportunities/",);
      setRowData(response.data);
    } catch (error) {
      console.error("Error fetching records:", error);
      setRowData([]);
    }
  },[]);

  const fetchUserInfo = useCallback(async () => {
    try {
      const roleResponse = await api.get("/user/role/", );
      const idResponse = await api.get("/user/id/", );
      setUserRole(roleResponse.data.role);
      setUserId(idResponse.data.id);
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  },[]);

  useEffect(() => {
    if (token) {
      fetchRecords();
      fetchUserInfo();
    }
  }, [token, fetchRecords, fetchUserInfo]);

  const onCellValueChanged = async (event) => {
    const { data } = event;
    try {
      // Ensure TCV is a number if it was edited
      const payload = {
        ...data,
        tcv: typeof data.tcv === 'string'
          ? parseFloat(data.tcv.replace(/[^0-9.]/g, ''))
          : data.tcv
      };

      await api.put(`/opportunities/${data.id}/`, payload);
      fetchRecords();
    } catch (error) {
      console.error("Error updating record:", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setToken(null);
    setUserRole("");
    setUserId(null);
  };

  const handleCreateOpportunity = async (e) => {
    e.preventDefault();

    try {
      // Format dates to ISO string and ensure numeric fields
      const payload = {
        ...newOpportunity,
        creator: userId,
        solicitation_release: new Date(newOpportunity.solicitation_release).toISOString().split('T')[0],
        response_due: new Date(newOpportunity.response_due).toISOString(),
        tcv: parseFloat(newOpportunity.tcv) || 0,
      };

      await api.post("/opportunities/", payload);
      fetchRecords();
      setShowNewOpportunityModal(false);
      // Reset form
      setNewOpportunity({
        client: '',
        opportunity: '',
        stage: '',
        solicitation_link: '',
        solicitation_release: null,
        response_due: '',
        response_link: '',
        asg_role: '',
        lead: '',
        division_vp: '',
        scope: '',
        vehicle: '',
        set_aside: '',
        tcv: '',
        incumbents: '',
        partners: '',
        status: 'New',
      });
    } catch (error) {
      console.error("Error creating opportunity:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setNewOpportunity(prev => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewOpportunity(prev => ({
      ...prev,
      [name]: value ? safeDateToISO(value) : null
    }));
  };

  const fetchAuditLogs = async (opportunityId) => {
    try {
      const response = await api.get(`/opportunities/${opportunityId}/audit_logs/`);
      setAuditLogs(response.data);
      setIsAuditLogModalOpen(true);
      setSelectedOpportunityId(opportunityId);
    } catch (error) {
      console.error("Error fetching audit logs:", error);
    }
  };

  const handleViewAuditLog = (opportunityId) => {
    fetchAuditLogs(opportunityId);
  };

  // TODO:
  // Consider adding a loading state during form submission

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            token ? (
              <div className="p-4">
                <div className="mb-4 flex justify-between items-center">
                  <h1 className="text-2xl font-bold">ASG Pipelines</h1>
                  <div className="space-x-4">
                    <button
                      onClick={() => setShowNewOpportunityModal(true)}
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    >
                      New Opportunity
                    </button>
                    <button
                      onClick={handleLogout}
                      className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                    >
                      Logout
                    </button>
                  </div>
                </div>

                {/* New Opportunity Modal */}
                {showNewOpportunityModal && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg w-full max-w-2xl">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-bold">New Opportunity</h2>
                        <button
                          onClick={() => setShowNewOpportunityModal(false)}
                          className="text-gray-500 hover:text-gray-700"
                        >
                          ✕
                        </button>
                      </div>

                      <form onSubmit={handleCreateOpportunity} className="grid grid-cols-2 gap-4">
                        {/* Required Fields */}
                        <div className="col-span-2">
                          <label className="block text-sm font-medium mb-1">Client *</label>
                          <input
                            type="text"
                            name="client"
                            value={newOpportunity.client}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                          />
                        </div>

                        <div>
                          <label className="block text-sm font-medium mb-1">Opportunity *</label>
                          <input
                            type="text"
                            name="opportunity"
                            value={newOpportunity.opportunity}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                          />
                        </div>

                        {/* Stage Dropdown */}
                        <div>
                          <label className="block text-sm font-medium mb-1">Stage *</label>
                          <select
                            name="stage"
                            value={newOpportunity.stage}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                          >
                            <option value="">Select Stage</option>
                            {STAGE_OPTIONS.map(option => (
                              <option key={option} value={option}>{option}</option>
                            ))}
                          </select>
                        </div>

                        <div>
                          <label className="block text-sm font-medium mb-1">Solicitation Link *</label>
                          <input
                            type="url"
                            name="solicitation_link"
                            value={newOpportunity.solicitation_link}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                          />
                        </div>

                        {/* Date Inputs */}
                        <div>
                          <label className="block text-sm font-medium mb-1">Solicitation Release *</label>
                          <input
                            type="date"
                            name="solicitation_release"
                            value={formatForDateInput(newOpportunity.solicitation_release)}
                            onChange={handleDateChange}
                            className="w-full p-2 border rounded"
                            required
                          />
                        </div>

                        <div>
                          <label className="block text-sm font-medium mb-1">Response Due *</label>
                          <input
                            type="datetime-local"
                            name="response_due"
                            value={newOpportunity.response_due}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                          />
                        </div>

                        <div>
                          <label className="block text-sm font-medium mb-1">Response Link *</label>
                          <input
                            type="url"
                            name="response_link"
                            value={newOpportunity.response_link}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                          />
                        </div>

                        {/* ASG Role Dropdown */}
                        <div>
                          <label className="block text-sm font-medium mb-1">ASG Role *</label>
                          <select
                            name="asg_role"
                            value={newOpportunity.asg_role}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                          >
                            <option value="">Select Role</option>
                            {ASG_ROLE_OPTIONS.map(option => (
                              <option key={option} value={option}>{option}</option>
                            ))}
                          </select>
                        </div>

                        <div className="col-span-2">
                          <label className="block text-sm font-medium mb-1">Lead *</label>
                          <input
                            type="text"
                            name="lead"
                            value={newOpportunity.lead}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                          />
                        </div>

                        <div className="col-span-2">
                          <label className="block text-sm font-medium mb-1">Division VP *</label>
                          <input
                            type="text"
                            name="division_vp"
                            value={newOpportunity.division_vp}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                          />
                        </div>

                        <div className="col-span-2">
                          <label className="block text-sm font-medium mb-1">Scope *</label>
                          <input
                            type="text"
                            name="scope"
                            value={newOpportunity.scope}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                          />
                        </div>

                        <div className="col-span-2">
                          <label className="block text-sm font-medium mb-1">Vehicle *</label>
                          <input
                            type="text"
                            name="vehicle"
                            value={newOpportunity.vehicle}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                          />
                        </div>

                        <div className="col-span-2">
                          <label className="block text-sm font-medium mb-1">Set Aside *</label>
                          <input
                            type="text"
                            name="set_aside"
                            value={newOpportunity.set_aside}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                          />
                        </div>

                        <div className="col-span-2">
                          <label className="block text-sm font-medium mb-1">TCV ($) *</label>
                          <input
                            type="number"
                            step="0.01"
                            name="tcv"
                            value={newOpportunity.tcv}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                          />
                        </div>

                        <div className="col-span-2">
                          <label className="block text-sm font-medium mb-1">Incumbents *</label>
                          <input
                            type="text"
                            name="incumbents"
                            value={newOpportunity.incumbents}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                          />
                        </div>

                        <div className="col-span-2">
                          <label className="block text-sm font-medium mb-1">Partners *</label>
                          <input
                            type="text"
                            name="partners"
                            value={newOpportunity.partners}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                          />
                        </div>

                        <div className="col-span-2">
                          <label className="block text-sm font-medium mb-1">Status *</label>
                          <input
                            type="text"
                            name="status"
                            value={newOpportunity.status}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                          />
                        </div>

                        <div className="col-span-2 mt-4 flex justify-end space-x-2">
                          <button
                            type="button"
                            onClick={() => setShowNewOpportunityModal(false)}
                            className="px-4 py-2 border rounded"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                          >
                            Create Opportunity
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}

                {/* AG Grid Table (keep existing table code) */}
                <div className="ag-theme-alpine" style={{ height: "calc(100vh - 120px)", width: "100%" }}>
                  <AgGridReact
                    modules={modules}
                    columnDefs={columnDefs}
                    rowData={rowData}
                    defaultColDef={{
                      sortable: true,
                      filter: true,
                      resizable: true,
                    }}
                    multiSortKey="ctrl" // Enable multi-level sorting using the Ctrl key
                    onGridReady={(params) => {
                      // Apply initial sort when grid is ready
                      params.api.applyColumnState({
                        state: [
                          { colId: 'stage', sort: 'asc' },
                          { colId: 'response_due', sort: 'asc' }
                        ],
                        defaultState: { sort: null } // Clear other sorts
                      });
                    }}
                    onCellValueChanged={onCellValueChanged}
                    isCellEditable={(params) => {
                      if (!params.data) return false;
                      return userRole === "Leads" || params.data.creator === userId;
                    }}
                    suppressNoRowsOverlay={true}
                    animateRows={true}
                  />
                </div>

                {/* Audit Log Modal */}
                {isAuditLogModalOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg w-full max-w-2xl">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-bold">
                          Audit Logs for Opportunity #{selectedOpportunityId}
                        </h2>
                        <button
                          onClick={() => setIsAuditLogModalOpen(false)}
                          className="text-gray-500 hover:text-gray-700"
                        >
                          ✕
                        </button>
                      </div>

                      <div className="max-h-96 overflow-y-auto">
                        <table className="w-full">
                          <thead>
                            <tr className="bg-gray-100">
                              <th className="p-2">Field</th>
                              <th className="p-2">Old Value</th>
                              <th className="p-2">New Value</th>
                              <th className="p-2">Changed By</th>
                              <th className="p-2">Changed At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {auditLogs.map((log) => (
                              <tr key={log.id} className="border-b">
                                <td className="p-2">{log.field_name}</td>
                                <td className="p-2">{log.old_value}</td>
                                <td className="p-2">{log.new_value}</td>
                                <td className="p-2">{log.changed_by}</td>
                                <td className="p-2">
                                  {new Date(log.changed_at).toLocaleString()}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="mt-4 flex justify-end">
                        <button
                          onClick={() => setIsAuditLogModalOpen(false)}
                          className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                )
                }
              </div>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route path="/login" element={<Login setToken={setToken} />} />
      </Routes>
    </Router>
  );
};

export default App;