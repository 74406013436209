import axios from "axios";

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor (adds token to headers)
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor (handles 401 errors)
api.interceptors.response.use(
  (response) => response, // Success: pass through
  (error) => {
    if (error.response?.status === 401) {
      // Clear stored token
      localStorage.removeItem("token");

      // Redirect to login (using window.location for reliability)
      window.location.href = "/login"; // Full page reload
    }
    return Promise.reject(error); // Pass other errors to `.catch()`
  }
);

export default api;

// Example API call
// const fetchData = async () => {
//   try {
//     const response = await api.get("/user/profile");
//     console.log(response.data);
//   } catch (error) {
//     console.error("Non-401 error:", error); // Handles 500, 404, etc.
//   }
// };