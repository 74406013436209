// src/utils/dateUtils.js
export const safeDateToISO = (date) => {
    try {
        if (!date) return null;
        const d = new Date(date);
        return isNaN(d.getTime()) ? null : d.toISOString();
    } catch {
        return null;
    }
};

export const formatForDateInput = (dateString) => {
    try {
        if (!dateString) return '';
        const date = new Date(dateString);
        return isNaN(date.getTime()) ? '' : date.toISOString().split('T')[0];
    } catch {
        return '';
    }
};
